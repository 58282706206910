const state = reactive({

    articles: [],
    total: 0

})

export function useCaseStudies() {

    async function fetchArticles(categories:any, per_page:any, page: any) {
        
        const route = useRoute()
        const storyblokApi = useStoryblokApi()
        const config = useRuntimeConfig()
        const cv = config.public.cv
        const version = route.query?._storyblok ? 'draft' : 'draft'

        const array = ref([])

        if(categories && categories.length > 0) {
            array.value = categories.toString()
        }

        const { data, total } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'case-studies/',
            version: version, 
            cv: Number(cv),
            resolve_relations: ['CaseStudiesArticleTemplate.categories'],
            filter_query: {
                component: {
                    in: 'CaseStudiesArticleTemplate'
                },
                categories: {
                    any_in_array: array.value
                }
            },
            page: page,
            per_page: per_page
        }) 

        state.articles = data.stories

        state.total = total

    }


    return {
        ...toRefs(state),
        fetchArticles,
    }
}